.called {
  color: #00ff5e;
}

.call_number {
  font-size: 80px;
  text-align: center;
}

.last-called-number-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  flex-direction: row-reverse;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-gutter: stable left;
  padding-right: 20px;
  margin-left: auto;
}

.last-called-number-container::-webkit-scrollbar {
  height: 5px; /* Adjust the height of the scrollbar */
}

.last-called-number-container::-webkit-scrollbar-track {
  background-color: transparent; /* Set the track color */
}

.last-called-number-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3); /* Set the thumb color */
  border-radius: 6px; /* Adjust the border radius of the thumb */
}

.last-called-number-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Set the thumb color on hover */
}

.last-called-number-circle {
  min-width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  border: 15px solid;
  color: #000;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.last-called-number-circle::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 2px solid;
}

.last-five-numbers {
  min-width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fff;
  border: 10px solid;
  color: #000;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.last-five-numbers::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 2px solid;
}

.border-b {
  border-color: rgb(204, 15, 15);
}

.border-b::before {
  border-color: rgb(176, 39, 39);
}

.border-i {
  border-color: rgb(255, 153, 0);
}

.border-i::before {
  border-color: rgb(255, 153, 0);
}

.border-n {
  border-color: rgb(128, 0, 70);
}

.border-n::before {
  border-color: rgb(128, 0, 70);
}

.border-g {
  border-color: rgb(4, 252, 4);
}

.border-g::before {
  border-color: rgb(4, 252, 4);
}

.border-o {
  border-color: rgb(0, 251, 255);
}

.border-o::before {
  border-color: rgb(0, 251, 255);
}

.winner-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: popupAnimation 0.5s ease-in-out;
}

.winner-title {
  font-size: 48px;
  font-weight: bold;
  color: #ff4d4d;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.winner-card {
  font-size: 24px;
  color: #4d94ff;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.close-btn,
.new-game-btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
}

.close-btn:hover {
  background-color: #45a049;
}

.new-game-btn {
  background-color: #ff9800;
  color: #fff;
  border: none;
}

.new-game-btn:hover {
  background-color: #e68a00;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #999;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lightbg {
  color: #9c737e;
}